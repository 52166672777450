<template>
  <div>
    <form @submit.prevent="submit">
      <div class="row">
        <loading-notification :show="loading" />
      </div>
      <div class="row">
        <div class="flex xs12 md6">
          <va-input
            color="info"
            v-model="form.name.value"
            :disabled="loading"
            :label="$t('layout.form.nameInput')"
            :error="!!form.name.errors.length"
            :error-messages="translatedErrors(form.name.errors)"
            @blur="validate('name')"
          >
            <va-popover
              :message="$t('layout.translation')"
              placement="top"
              slot="append"
            >
              <va-button
                flat
                small
                type="button"
                color="primary"
                style="margin-right: 0;"
                icon="entypo entypo-language"
                @click="openTranslator"
              />
            </va-popover>
          </va-input>
        </div>
        <div class="flex xs12 md6">
          <va-input
            color="info"
            :value="$t(form.name.value).toString()"
            :disabled="true"
            :label="$t('layout.form.name_translated_input')"
          />
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-button
            color="primary"
            :disabled="loading"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-save"
            >
              {{ $t('layout.form.save') }}
            </text-loading>
          </va-button>
        </div>
      </div>
    </form>
    <va-modal
      ref="translatorModal"
      v-model="translatorModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.translations')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closeTranslatorModal"
    >
      <translator-modal
        :edit="true"
        :path="form.name.value"
        translation-type="both"
        @saved="closeTranslatorModal"
      />
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')

export default {
  name: 'methods-form',
  components: {
    TranslatorModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    method: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      translatorModal: false,
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.method.id === 0
    },
  },
  watch: {
    method (val) {
      this.setMethod(val)
    },
    async currentLocale (val) {
      await this.$nextTick()
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setMethod (val) {
      this.setFormData(val)
      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.method) {
        this.setMethod(this.method)
      }
    },
    checkEmpty (field) {
      return field === undefined || field === null || field === ''
    },
    openTranslator () {
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const method = this.getFormData(this.method)
      if (method.regions) {
        delete method.regions
      }
      this.$emit('submit', method)
    },
  },
}
</script>
